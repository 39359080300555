.open-small-chat {
    color: #FFFFFF !important;
}


.small-chat-box {
    width: 300px;
}


.sk-spinner {
    position: absolute;
    bottom: 0px;
}