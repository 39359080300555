.ChatFlow .col-lg-2,
.ChatFlow .col-lg-8
{
    padding:0 !important;
    margin:0 !important;
}



.btn-lock  {
    /*height: 35px !important;*/
}

.bar-zoom {
    height: 35px !important;
}

.bar-zoom > span {
    margin-top: -4px;
}

.ChatFlow .row {
    padding:0 !important;
    margin:0 !important;
}