.UsuarioLista .table tr {
    cursor: pointer;
}


.UsuarioLista #uf_codigo {
    width: 180px;
    height: 35px;
}

.UsuarioLista #nivel {
    height: 35px;
}

.UsuarioLista #btnBusca {
    height: 35px;
}

.UsuarioLista .risk {
    text-decoration: line-through;
}

.UsuarioLista  td {
    cursor: pointer;
}
