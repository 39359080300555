.modal900 {
    width: 900px !important;
    z-index:9999 !important;
}



.modal-dialog {
    max-width:900px !important;
}

.UsuarioCadastro select {
    height: 36px !important;
}