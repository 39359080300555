.Interaction .hr {
    border-top: 1px dashed #222222;
    margin-top: 30px;
    margin-bottom: 30px;
}

.Interaction .hr1 {
    border-top: 1px dashed #be2a2a;
    margin-top: 30px;
    margin-bottom: 30px;
}


.Interaction .from {
    /*width: 300px;*/
    height: 36px;

}


.Interaction label {
    font-weight: 600;
}


.Interaction .btn {
    height: 36px;
}

.Interaction input, .Interaction select {
    height: 36px !important;
}


.Interaction #SQuestion a {
    color:rgb(47, 7, 160) !important;
}


#AutoGenerated input {
    background-color: #f3faf8 !important;
}


#IAnswer input[type='radio'] {
    width: 22px;
    height: 22px;
}


#IAnswer input[type='checkbox'] {
    width: 22px;
    height: 22px;
}


.right {
    float: right;
}


.left {
    float: left;
}


.clear {
    clear: both;
}

.hide {
    display: none;
}


.Interaction .tipo input {
    margin-left: 0px;
    margin-right: 7px;
}


.Interaction .m_origens a, .red {
    color:#ff0000;
}

.Interaction .m_origens a:hover {
    color:#1c02aa;
}


.Interaction .tipo label {
    margin-left: 0px;
    margin-right: 14px;
    margin-top: 7px;
    vertical-align: top;
    font-size: 14px;
    cursor: pointer;
}

  

